/*--------head------------*/
.head {
  padding: 20px 0;
  color: #fff;
}
.logo h1 {
  font-size: 21px;
  font-weight: 700;
  color: #343a40;
}

.logo img {
  width: 150px;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
  font-size: 18px;
}
header ul li a:hover {
  color: #eceae9;
}
.start {
  font-size: 24px;
  font-weight: 700;
  background-color: #f36523;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}

/* Highlight external link */
nav ul li a.external-link {
  color: #ff6347; /* Example: Tomato color */
  font-weight: bold;
  background-color: #f0f8ff; /* Light background */
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* Hover effect for external link */
nav ul li a.external-link:hover {
  color: #ffffff;
  background-color: #ff6347;
  transform: scale(1.1);
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #f36523;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #f36523;
  }
}
