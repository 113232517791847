.price .items {
  padding: 40px;
  text-align: center;
}
.price h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #1eb2a6;
}
.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}
.price p {
  color: grey;
  margin: 40px 0;
}
/*--------------faq-------------*/
/* .faq .container {
  max-width: 70%;
  border-radius: 5px;
}
.faq .box {
  background-color: #fff;
  margin-bottom: 20px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion:hover {
  background-color: #1eb2a6;
  color: #fff;
} */

/*--------------faq-------------*/


/*========================== Reusable Detail Page STARTING STARTING ===============================*/

.detail-page {
  padding: 50px 0;
}

.detail-page .container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.box {
  margin-bottom: 15px;
}

.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: background-color 0.4s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.accordion:hover {
  background-color: #f1f1f1;
}

.accordion h2 {
  margin: 0;
}

.accordion span {
  font-size: 18px;
}

.text {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  padding: 0 18px;
}

.module {
  margin-top: 10px;
}

.module h3 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.module ul {
  list-style: disc;
  padding-left: 20px;
}

.module li {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .accordion {
    font-size: 16px;
    padding: 15px;
  }

  .module h3 {
    font-size: 14px;
  }

  .module ul {
    padding-left: 15px;
  }
}
/*========================== Reusable Detail Page ENDING ENDING ===============================*/

